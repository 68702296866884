<template>
  <div id="app" data-view>
    <main class="main">
      <div class="main-row">
        <a href="/product/karamucho" class="main-col karamucho">
          <div class="main-bg karamucho-bg"></div>
          <div class="main-ribbon">
            <div class="main-ribbon__bg"></div>
          </div>
          <img class="main-fire" :src="webp(require(`../public/img/bg/bg-fire-karamucho-${$i18n.locale}.png`))" alt="">
          <img class="main-bimbim" :src="webp(require(`../public/img/karamucho/bg-bimbim.png`))" alt="">
          <img :src="webp(require(`../public/img/karamucho/chili-fade.png`))" alt="" class="main-fade">
          <div class="main-package">
            <img class="main-package__img" :src="webp(require(`../public/img/karamucho/${prefix}/${ $i18n.locale == 'vi' ? pack.img_vi : pack.img_en }`))" alt="">
            <img class="main-package__img__hover" :src="webp(require(`../public/img/karamucho/${prefix}/${ $i18n.locale == 'vi' ? pack.img_hover_vi : pack.img_hover_en }`))" alt="">
            <img class="main-package__fly" :src="webp(require(`../public/img/karamucho/snack-fly.png`))" alt="">
          </div>
          <img class="main-fall" :src="webp(require(`../public/img/karamucho/snack-fall.png`))" alt="">
        </a>
        <a href="/product/koimucho" class="main-col koimucho">
          <div class="main-bg koimucho-bg"></div>
          <div class="main-ribbon">
            <div class="main-ribbon__bg koimucho-ribbon"></div>
          </div>
          <img class="main-fire" :src="webp(require(`../public/img/bg/bg-fire-koimucho-${$i18n.locale}.png`))" alt="">
          <img class="main-bimbim" :src="webp(require(`../public/img/koimucho/bg-bimbim.png`))" alt="">
          <img :src="webp(require(`../public/img/koimucho/popcorn-fade.png`))" alt="" class="main-fade">
          <div class="main-package">
            <img class="main-package__img" :src="webp(require(`../public/img/koimucho/${prefix}/${ $i18n.locale == 'vi' ? pack.img_vi : pack.img_en }`))" alt="">
            <img class="main-package__img__hover" :src="webp(require(`../public/img/koimucho/${prefix}/${ $i18n.locale == 'vi' ? pack.img_hover_vi : pack.img_hover_en }`))" alt="">
            <img class="main-package__fly" :src="webp(require(`../public/img/koimucho/snack-fly.png`))" alt="">
          </div>
          <img class="main-fall" :src="webp(require(`../public/img/koimucho/snack-fall.png`))" alt="">
        </a>
        <a href="/corporate/" class="logo">
          <div class="logo-img">
            <img :src="webp(require(`../public/img/index/logo.png`))" alt="">
          </div>
          <div class="logo-link">
            <img :src="webp(require(`../public/img/index/logo-link-${$i18n.locale}.png`))" alt="">
          </div>
        </a>
      </div>
    </main>
    <!-- <Popup :windowWidth="winWidth" :link="linkPopup" :locale="locale" :page="page"/> -->
  </div>
</template>

<script>
  // import Popup from './components/Popup'

  export default {
    name: 'app',
    metaInfo () {
      return {
        title: 'Koikeya | Snack – Cay ngon tan chảy, bốc liền liền tay',
        titleTemplate: '%s',
        meta: [
          { name: 'description', content: 'We are a comprehensive snack maker that sells potato chips, Koikeya pride potatoes, karamucho, scones, and porinkey', vmid: 'description' },
        ]
      }
    },
    data() {
      return {
        page: 'home',
        linkPopup: '/product/koimucho/#promotion',
        locale: localStorage.locale || 'vi',
        winWidth: 0,
        isMobile: false,
        prefix: '',
        pack: {
          img_en: 'img-package-en.png',
          img_hover_en: 'img-package-hover-en.png',
          img_vi: 'img-package-vi.png',
          img_hover_vi: 'img-package-hover-vi.png'
        }
      }
    },
    created() {
      this.winWidth = window.innerWidth;
      this.checkDevice()
    },
    mounted() {
      document.onreadystatechange = () => {
        if (document.readyState === "complete") {
          document.querySelector('html').classList.remove('loading')

          this. $nextTick(function () {
            this.animate()
          })
        }
      }
    },
    methods: {
      animate() {
        var self = this
        let el = document.querySelector('.main-row')
        el.classList.add('animate')
        setTimeout(function() {
          el.classList.add('hover');
          if ( self.isMobile ) {
            el.classList.add('open');
          }
        }, 3500)
      },
      checkDevice() {
        if ( this.winWidth <= 800 ) {
          this.isMobile = true;
          this.prefix = 'sp'
        } else {
          this.prefix = 'pc'
        }
      } 
    }
  }
</script>


<style lang="scss"> 
  @import './assets/scss/all.scss';
  html {
    width: 100%;
  }
  .main {
    &-row {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      height: 100vh;
      overflow: hidden;

      @include media(set-max-w, 800) {
        height: initial;
      }
    }
    &-col {
      position: relative;
      width: 50%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      overflow: hidden;

      @include media(set-max-w, 800) { 
        width: 100%;
        height: 50vh;
      }
    }
    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;     
    }
    &-ribbon {
      position: absolute;
      left: 0;
      top: 1.2rem;
      width: 100%;
      padding: 0 1rem;
      opacity: 0;
      transition: 1.4s 1s ease-in-out;
      z-index: 99;

      @include media(phone) {
        top: 0
      }
      &__bg {
        width: 100%;
        height: 3.5rem;
        background: url(../public/img/bg/bg-ribbon.png) repeat-x left center;

        @include media(phone) {
          background: url(../public/img/bg/bg-ribbon.png) repeat-x left center / 50px;
        }
      }
    }
    &-fire {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 4;
      transform: translateY(100%);

      @include media(set-max-w, 800) {
        display: none
      }
    }
    &-bimbim {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 100%;
      z-index: 1;
      opacity: 0;

      @include media(tablet) {
        bottom: -14rem
      }
    }
    &-fade {
      position: absolute;
      top: 5%;
      left: 0;
      width: 100%;
      z-index: 1;
      opacity: 0;
    }
    &-package {
      position: relative;
      z-index: 2;
      padding-bottom: 18%;
      margin: 0 auto;
      padding-right: 7rem;
      width: 53%;
      z-index: 2;
      opacity: 0;

      @include media(tablet) {
        padding-right: 0;
        padding-bottom: 10%;
        width: 30%;
      }
      @include media(phone) {
        bottom: -4%;
        width: 39%;
        padding-right: 0;
      }
      &__img {
        &__hover {
          display: none;
        }
      }
      &__fly {
        width: 86%;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(0) scale(.6);
        opacity: 0;
        z-index: -1;
        transition: transform .4s ease-in-out, opacity .3s .2s ease-in-out;

        @include media(phone) {
          //left: calc(50% + .3rem);
        }
      }
    }
    &-fall {
      position: absolute;
      left: 0;
      bottom: 12%;
      transform: translate(0, -225%);
      z-index: 3;
      opacity: 0;

      @include media(tablet) {
        bottom: -2%
      }
      @include media(phone) {
        bottom: 0
      }
    }
  }
  .logo {
    display: block;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -30%);
    //width: 26.15%;
    color: #000000;
    z-index: 7;
    transition:  .4s ease;

    @include media(tablet) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20%;
    }
    @include media(phone) {
      top: 48%;
      width: 25%;
    }
    &-img {
      align-items: center;
      justify-content: center;
      flex-direction: column;
      display: flex;
      background: #ffffff;
      margin-bottom: .2rem;
    }
    &-link {
      text-align: center;
      background: #ffffff;

      span {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          right: -4.5rem;
          top: 0;
          width: 3.5rem;
          height: 100%;
          background: url(../public/img/icon/icon-arrow-half.png) no-repeat left center
        }
      }
    }
  }
  .hover {
    .main-fade {
      transition: .3s ease-out;
    }
    .main-col {
      &:hover {
        .main-package{
          &__fly {
            opacity: 1;
            transform: translateY(-90%) scale(1);
            //width: 66%;
            z-index: 1;
          }
          &__img {
            display: none;
            &__hover {
              display: block;
            }
          }    
        }
        .main-fade {
          transform: translateY(-15rem) scale(1.1);
          transition: 4s cubic-bezier(0.19, 1, 0.22, 1);
          }
        }
      }
    
  }
  .animate {
    .main {
      &-ribbon,
      &-bimbim,
      &-package {
        animation: fade 1.5s .5s forwards;
      }
      &-fall {
        animation: fall 1.5s 1.5s forwards
      }
      &-fade {
        animation: chilifade 1s 2.5s forwards
      }
      &-fire {
        animation: fireup 1s 3.5s forwards
      }
      &-package {
        &__fly {
          @include media(set-max-w, 800) {
            animation: flysp .4s 3.5s forwards
          }
        }
        
      }
    }
    
    &.open {
      .main-package {
        &__img {
          display: none;
          &__hover {
            display: block;
          }
        }    
      }
    }
  }
  .karamucho {
    &-bg {
      background: #ff5100;
      background: radial-gradient(circle, #ffff00 0%, #ff5100 100%);
    }
  }
  .koimucho {
    &-bg {
      background: rgba(0,127,255,1);
      background: radial-gradient(circle, rgba(100,249,255,1) 0%, rgba(0,127,255,1) 100%);
    }
    &-ribbon {
      @include media(set-max-w, 800) {
        display: none
      }
    }
  }
</style>
